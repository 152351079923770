





















































































import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import {Network, Node, Organization} from '@stellarbeat/js-stellar-domain';
import Store from '@/store/Store';

import {BBadge, BIconInfoCircle, BIconShield, BPagination, BTable, VBTooltip} from 'bootstrap-vue';
import OrganizationActions from '@/components/organization/sidebar/organization-actions.vue';

@Component(
    {
        components: {
            OrganizationActions,
            BTable,
            BPagination,
            BIconShield: BIconShield,
            BBadge: BBadge,
            BIconInfoCircle: BIconInfoCircle
        },
        directives: {'b-tooltip': VBTooltip}
    })
export default class OrganizationsTable extends Vue {
    @Prop({default: ''})
    public filter!: string;
    @Prop()
    public fields!: any;
    @Prop()
    public organizations!: Organization[];
    @Prop({default: 200})
    public perPage!: number;
    @Prop({default: 'subQuorum30DAvailability'})
    public sortBy!: string;
    public sortDesc: boolean = true;

    public currentPage: number = 1;

    get store(): Store {
        return this.$root.$data.store;
    }

    get network() {
        return this.store.network;
    }

    get totalRows(): number {
        return this.organizations.length;
    }
}
